import React from 'react';

import Input from '@components/input/Input.tsx';
import { addCursorList, deleteCursorList, setCursorList } from '@functions/listsHandler.ts';
import { CursorListsT } from '@global/types.ts';

import I from '../types.ts';

const setSelectProps = function (this: I, first: boolean, model: any): ObjT {
    const otherFields: ObjT = {};
    const selectProps = first ? this.props.selectProps! : this.props.nextSelectProps!;

    (Object.keys(selectProps) as (keyof typeof selectProps)[]).forEach((key) => {
        const thisProps =
            typeof selectProps[key] === 'function'
                ? selectProps[key].call(this, model)
                : selectProps[key];
        const resultKey =
            typeof selectProps[key] === 'function'
                ? (thisProps as { key: string }).key
                : selectProps[key];
        const resultValue =
            typeof selectProps[key] === 'function'
                ? (thisProps as { text: string }).text
                : model?.[key];

        if (resultKey) {
            otherFields[resultKey] = resultValue || undefined;
        }
    });

    return otherFields;
};

const callback = async function (this: I, id: string, first: boolean, modelId: string, model: any) {
    const {
        prop,
        onChange,
        setSelectChange,
        multi,
        addArrayItem,
        deleteArrayItem,
        nextSelectList,
    } = this.props;
    const otherFields = setSelectProps.call(this, first, model);

    if (multi) {
        if (model) {
            addArrayItem!({ key: prop, item: { key: modelId, ...otherFields } });
        } else {
            deleteArrayItem!([`${prop}.${modelId}`], 'key');
        }
    } else {
        const updateFields = setSelectChange ? setSelectChange(model) : {};

        onChange({
            ...(!nextSelectList || !first ? { [prop]: model?._id || null } : {}),
            ...otherFields,
            ...updateFields,
            UPDATED_KEY: new Date().getTime(),
        });

        await deleteCursorList({ id });

        if (first && nextSelectList) {
            const listName = nextSelectList(modelId) as CursorListsT['type'];
            const target = this.parent.current!.querySelector(
                '.v2widgetField__field',
            ) as HTMLElement;
            const nextId = `${id}-double`;

            await addCursorList({
                e: { target },
                list: {
                    _id: nextId,
                    type: listName,
                    dir: 'bottom',
                    callback: callback.bind(this, nextId, false),
                    checkClose: () => !document.querySelector('.body__v2popup._filter'),
                },
                settings: { targetClassName: '.v2widgetField__field' },
            });
        }
    }
};

const renderSelect: I['renderSelect'] = function () {
    const {
        name,
        value,
        regExp,
        concat,
        selectList,
        cardName,
        inputSupport,
        disabled,
        multi,
        selectTextKey,
    } = this.props;

    const selectText = multi
        ? ((value as any[]) || []).map((item) => item[selectTextKey!]).join(', ')
        : this.props.selectText;

    const id = `select-${name}-${cardName}`;
    const defaultQuery =
        typeof this.props.defaultQuery === 'function'
            ? this.props.defaultQuery(this.props.model, this.props)
            : this.props.defaultQuery;

    const currents = multi
        ? (value as { key: string }[]).map((item) => item.key) || []
        : value
          ? [value as string]
          : [];

    const props =
        !disabled &&
        setCursorList(
            {
                _id: id,
                type: selectList!,
                dir: 'bottom',
                currents,
                filterName: cardName,
                multi,
                defaultQuery,
                callback: callback.bind(this, id, true),
                checkClose: () => !document.querySelector('.body__v2popup._filter'),
            },
            { targetClassName: '.v2widgetField__field' },
        );

    return (
        <>
            <div className="v2widgetField__field _select" {...props}>
                <Input
                    name={name}
                    value={selectText || ''}
                    onChange={async () => undefined}
                    support={inputSupport || '–'}
                    regExp={regExp}
                    concat={concat}
                    disabled={true}
                    isStatic={true}
                />
            </div>
        </>
    );
};

export default renderSelect;
