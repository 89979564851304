import UserT from '@global/models/User';

const getMainGroupId = ({ user }: { user: UserT }): string | null =>
    localStorage.getItem(`dealGroup-${user?.idOfCurrentCorporation}`);

const setMainGroupId = ({ user, id }: { user: UserT; id: string }): void => {
    localStorage.setItem(`dealGroup-${user?.idOfCurrentCorporation}`, id);

    document.dispatchEvent(new CustomEvent('changeMainDealGroup'));
};

export { setMainGroupId, getMainGroupId };
